import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"


export default function NotFound() {
  return (
    <Layout>
      <h2 className="text-4xl py-4 font-semibold">ページが見つかりません。</h2>
    </Layout>
  )
}

export const Head = () => <Seo title="404 Not Found" />
